<template>
  <MainLayout>
    <template v-slot:block>
      {{
        sourceObject.results
          ? sourceObject.results.is_active === false
            ? "Заблокирован -"
            : ""
          : ""
      }}
    </template>
    <template v-slot:navbar-title>{{
      sourceObject.results ? sourceObject.results.name : ""
    }}</template>
    <template v-slot:navbar-btn-wrap>
      <BlockPopup
        v-if="
          sourceObject.results &&
            sourceObject.results.is_active &&
            $store.getters.getCurrentRole !== 50 &&
            $store.getters.getCurrentRole !== 40
        "
        name="Объект"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :block-function="banObject"
      ></BlockPopup>
      <UnBlockPopup
        v-if="
          !(sourceObject.results && sourceObject.results.is_active) &&
            $store.getters.getCurrentRole !== 50 &&
            $store.getters.getCurrentRole !== 40
        "
        name="Объект"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :un-block-function="unBlockObject"
      />
      <DeletePopup
        v-if="
          $store.getters.getCurrentRole !== 40 &&
            $store.getters.getCurrentRole !== 50
        "
        name="Объект"
        :title="sourceObject.results ? sourceObject.results.name : ''"
        :delete-function="deleteObject"
      />
      <router-link
        v-if="
          urlQuery &&
            urlQuery.id &&
            $store.getters.getCurrentRole !== 40 &&
            $store.getters.getCurrentRole !== 50
        "
        :to="`/objectsEdit/` + urlQuery.id"
        class="btn btn-edit btn_color_grey"
        >редактировать</router-link
      >
    </template>
    <SlideLayout :tabs="slideTabs">
      <template v-slot:info>
        <InfoLayout :tabs="infoTabs">
          <template v-slot:object_data>
            <div class="keep__title">
              <p class="keep__text">Данные объекта</p>
              <!--              <button class="btn btn_color_grey btn-edit">Редактировать</button>-->
            </div>
            <BasicTableWithoutHeader
              v-if="sourceObject.results"
              :table-data="objectInfo"
            >
              <template v-slot:item.4.value="{ item, keyName, value }">
                <div class="d-flex flex-row align-center justify-center">
                  <div>
                    {{ value }}
                  </div>
                  <v-spacer />
                  <div
                    v-if="
                      $store.getters.getCurrentRole !== 40 &&
                        $store.getters.getCurrentRole !== 50
                    "
                  >
                    <v-switch
                      :input-value="sourceObject.results.accounts_enabled"
                      @change="changeAccount"
                      color="#95c23d"
                      inset
                      :label="
                        sourceObject.results.accounts_enabled
                          ? 'Включено'
                          : 'Выключено'
                      "
                    ></v-switch>
                  </div>
                </div>
              </template>

              <template v-slot:item.5.value="{ item, keyName, value }">
                <div class="d-flex flex-row align-center justify-center">
                  <div
                    v-if="
                      $store.getters.getCurrentRole !== 60 ||
                        ($store.getters.getCurrentRole !== 70 &&
                          sourceObject.results.type !== 'kindergarten')
                    "
                  >
                    {{ value }}
                  </div>
                  <v-spacer />
                  <div
                    v-if="
                      $store.getters.getCurrentRole === 60 ||
                        ($store.getters.getCurrentRole === 70 &&
                          sourceObject.results.type !== 'kindergarten')
                    "
                  >
                    <v-switch
                      :input-value="sourceObject.results.guest_access_allowed"
                      @change="changeGuestAccessAllowed"
                      color="#95c23d"
                      inset
                      :label="
                        sourceObject.results.guest_access_allowed
                          ? 'Включено'
                          : 'Выключено'
                      "
                    ></v-switch>
                  </div>
                </div>
              </template>
            </BasicTableWithoutHeader>
          </template>
        </InfoLayout>
      </template>
      <template v-slot:personal_accounts>
        <ObjectsAccounts />
      </template>
      <template v-slot:controllers>
        <ObjectsControllers />
      </template>
      <template v-slot:physical_cards>
        <ObjectsCards />
      </template>
      <template v-slot:users_group>
        <ObjectsUsersGroup />
      </template>
      <template v-slot:users>
        <ObjectsUsers />
      </template>
      <template v-slot:pass_log>
        <ObjectPassageLog />
      </template>
      <template v-slot:payments>
        <ObjectsPayments />
      </template>
    </SlideLayout>
  </MainLayout>
</template>

<script>
import InfoLayout from "@/components/MoreInfo/InfoLayout";
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import * as query from "@/helpers/query";
import ObjectsAccounts from "@/components/info/objects/ObjectsAccounts";
import ObjectsCards from "@/components/info/objects/ObjectsCards";

import ObjectsControllers from "@/components/info/objects/ObjectsControllers";
import ObjectsPayments from "@/components/info/objects/ObjectsPayments";
import ObjectsUsers from "@/components/info/objects/ObjectsUsers";
import ObjectsUsersGroup from "@/components/info/objects/ObjectsUsersGroup";
import slideTabsObjects from "@/helpers/slideTabs/objects";
import DeletePopup from "@/components/popup/DeletePopup";
import BlockPopup from "@/components/popup/BlockPopup";
import ObjectPassageLog from "@/components/info/objects/ObjectPassageLog";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import {
  patchObjectByIdRequest,
  patchObjectGuestAccessByIdRequest
} from "@/helpers/api/objects";
import UnBlockPopup from "@/components/popup/UnBlockPopup";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import phoneFilter from "@/helpers/filters/phoneFilter";

export default {
  name: "ObjectsInfosPages",
  components: {
    UnBlockPopup,
    ObjectPassageLog,
    BlockPopup,
    DeletePopup,
    BasicTableWithoutHeader,
    SlideLayout,
    MainLayout,
    InfoLayout,
    ObjectsAccounts,
    ObjectsCards,
    ObjectsControllers,
    ObjectsPayments,
    ObjectsUsers,
    ObjectsUsersGroup
  },
  data: () => ({
    accounts_enabled: false,
    type: "objects",
    queries: query.organizations,
    infoTabs: [
      {
        text: "Данные объекта",
        value: "object_data"
      }
    ]
  }),
  computed: {
    sourceObject() {
      return this.$store.getters.getObjectInfo;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    objectInfo() {
      return [
        {
          text: "Название",
          value: this.sourceObject.results.name
        },
        {
          text: "Тип объекта",
          value: dictionariesHelper.objectType[this.sourceObject.results.type]
        },
        {
          text: "Город",
          value: this.sourceObject.results.city_details.name
        },
        {
          text: "Адрес",
          value: this.sourceObject.results.address
        },
        {
          text: "Количество лицевых счетов",
          value: this.sourceObject.results.accounts_count
        },
        {
          text: "Гостевой доступ на объект",
          value: this.sourceObject.results.guest_access_allowed ? "" : ""
        },
        {
          text: "Дилер",
          value: this.sourceObject.results.organization_diller
            ? this.sourceObject.results.organization_diller.name
            : "-"
        },
        {
          text: "Компания",
          value: this.sourceObject.results.organization_details
            ? this.sourceObject.results.organization_details.name
            : "-"
        },
        {
          text: "Обслуживающая организация",
          value: this.sourceObject.results.service_org_display
        },
        {
          text: "Техник/телефон",
          value: this.sourceObject.results?.service_technician
            ? this.sourceObject.results?.service_technician?.full_name +
              "/" +
              phoneFilter.execute(
                this?.sourceObject?.results?.service_technician?.phone_number
              )
            : "-"
        }
      ];
    },
    slideTabs() {
      return slideTabsObjects
        .filter(el => el.userRole.includes(this.$store.getters.getCurrentRole))
        .filter(el => {
          if (el.showKindergarten) {
            let kindergartenShow =
              this.$store.getters?.getObjectInfo?.results?.type ==
              "kindergarten";
            let showTechnic =
              this.$store.getters.getUserId ==
              this.$store.getters?.getObjectInfo?.results?.service_technician
                ?.id;
            let showAdmin = this.$store.getters.getCurrentRole == 50;
            return (showTechnic || showAdmin) && kindergartenShow;
          } else {
            return true;
          }
        })
        .map(slide => {
          switch (slide.value) {
            case "personal_accounts": {
              return {
                ...slide,
                count: this.sourceObject.results?.accounts_count
              };
            }
            case "controllers": {
              return {
                ...slide,
                count: this.sourceObject.results?.controllers_count
              };
            }
            case "physical_cards": {
              return {
                ...slide,
                count: this.sourceObject.results?.cards_count
              };
            }
            case "users_group": {
              return {
                ...slide,
                count: this.sourceObject.results?.access_groups_count
              };
            }
            case "users": {
              return {
                ...slide,
                count: this.sourceObject.results?.users_count
              };
            }
            default: {
              return slide;
            }
          }
        });
    }
  },
  methods: {
    setObjectId() {
      localStorage.setItem("ObjectId", this.urlQuery.id);
      localStorage.removeItem("KindergartenId");
      localStorage.removeItem("organization");
    },
    changeAccount(status) {
      patchObjectByIdRequest({
        id: this.urlQuery.id,
        data: { accounts_enabled: status }
      }).then(() => {
        this.$store.commit("SET_ACCOUNTS_ENABLED", status);
      });
    },
    changeGuestAccessAllowed(status) {
      patchObjectGuestAccessByIdRequest({
        id: this.urlQuery.id,
        data: { guest_access_allowed: status }
      }).then(() => {
        this.$store.commit("SET_GUESTACCESS_ENABLED", status);
      });
    },
    deleteObject() {
      this.$store
        .dispatch("deleteObject", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.object)
          );
        });
    },
    banObject() {
      this.$store
        .dispatch("banObject", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.ban(successMessageDictionary.object)
          );
        });
    },
    unBlockObject() {
      this.$store
        .dispatch("unBlockObject", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.unBan(successMessageDictionary.object)
          );
        });
    }
  },
  created() {
    this.setObjectId();
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query, type: this.type }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("getObjectInfoById").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("getObjectInfoById").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearOrganizationPage").then(() => next());
  }
};
</script>

<style scoped></style>
